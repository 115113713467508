import React, { useState } from 'react'
import { Formik, Form } from 'formik';
import * as yup from 'yup'
import useFetch from 'use-http'
import URLSearchParams from '@ungap/url-search-params'

import Box from 'components/Box'
import Text from 'components/Text'
import Input from 'components/Input'
import Board from 'components/Board'
import Button from 'components/Button'
import ContentLayout from 'components/ContentLayout'

import Captcha from '../Captcha';
import captchaShape from '../Captcha/shape';
import UploadFile from '../ApplyPage/UploadFile'
import { uploadText } from '../ApplyPage/EventForm'

import idSchema from '../../utils/idSchema'

const formData = [
  {
    label: '線上聲請編號 (擇一填寫)',
    name: 'webapplyforno',
  },
  {
    label: '調解受理編號 (擇一填寫)',
    name: 'adviceno',
  },
  {
    label: '身分證字號',
    name: 'national_number'
  },
  {
    label: 'E-mail',
    name: 'email'
  },
]

const validationSchema = yup.object().shape({
  webapplyforno: yup.string().length(8),
  adviceno: yup.string().length(11),
  captcha: captchaShape,
  national_number: idSchema.required(),
  email: yup.string().email().required(),
  upload: yup.array().min(1).required(),
})

const params = typeof window !== 'undefined' && new URLSearchParams(window.location.search)
const initailValues = ['webapplyforno', 'adviceno', 'email'].reduce((all, key) => {
  if (params && params.has(key)) {
    all[key] = params.get(key)
  }
  return all
}, {})

const AttachPage = () => {
  const { post } = useFetch('/attach')
  const [formErr, setFormErr] = useState()
  return (
    <ContentLayout title="補正文件上傳">
      <Board>
        <Formik
          initialValues={initailValues}
          onSubmit={async ({ code, ...values }, { setSubmitting, setFieldError }) => {
            setFormErr(null)
            const res = await post(Object.assign({ ...code }, values))
            if (res['captcha.input']) {
              setFieldError('captcha.uuid', 'Invalid')
            }
            if (res.adviceno || res.webapplyforno) {
              setFieldError('webapplyforno', '請擇一填寫')
              setFieldError('adviceno', '請擇一填寫')
            }
            if (res.error) {
              setFormErr(res.error)
              setFieldError('captcha.uuid', 'Refresh')
            }

            if (res.ok) {
              // 上傳成功
            }
            setSubmitting(false)
          }}
          validationSchema={validationSchema}
        >
          {({ isSubmitting }) => (
            <Form>
              {formData.map(({ label, name }) => (
                <Input.Field my="1em" name={name} label={label} key={name} />
              ))}
              {uploadText.map(({ label, buttonName, type }, index) => (
                <UploadFile
                  key={index}
                  label={label}
                  type={type}
                  buttonName={buttonName}
                  index={index}
                />
              ))}
              <Box textAlign="center" mt="1.5em">
                <Captcha />
                <Button disabled={isSubmitting} mt="1.5em" type="submit">送出</Button>
                {formErr && <Text mt="0.5em" color="danger">{formErr}</Text>}
              </Box>
            </Form>
          )}
        </Formik>
      </Board>
    </ContentLayout>
  )
}

export default AttachPage
